<template>
  <div class="container">
    <SecondNav></SecondNav>
    <RaisingNav></RaisingNav>
    <div class="swinediseaseTop">
        <h1>热点关注</h1>
        <ul>
            <li class="flex" v-for="(item,idx) in hot" :key="idx">
                <a href="#"><p>{{item.diseaseName}}</p></a>
                <p>hot! !</p>
            </li>
        </ul>
    </div>
    <div class="swinediseaseCenter">
        <ul>
            <li class="flex" v-for="(item,idx) in list" :key="idx">
                <div>{{item.typeName}}:</div>
                <div>
                    <ul class="flex" >
                        <li v-for="(data,sum) in item.diseaseList" :key="sum" @click="son(data,sum)">{{data.diseaseName}}</li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
    <div class="swinediseaseBottom">
        <h1>{{contents.diseaseName}}</h1>
        <div>
            <p v-html="contents.detail">{{contents.detail}}</p>
        </div>
    </div>
  </div>
</template>

<script>
import { getdiseaseHot, getdisease } from '@/api/swinedisease'
import SecondNav from '../../about/components/secondNav'
import RaisingNav from '../components/raisingNav.vue'
export default {
  components: {
    SecondNav,
    RaisingNav
  },
  data () {
    return {
      hot: '',
      list: '',
      contents: ''
    }
  },
  methods: {
    gethot () {
      getdiseaseHot({
        hotted: 1,
        sicked: 0
      }).then((res) => {
        this.hot = res.data.records
        
      })
    },
    getlits () {
      getdisease({
        sicked: 0
      }).then((res) => {
        this.list = res.data
        if(res.data[0].diseaseList[0]){
          this.contents = res.data[0].diseaseList[0]
        }
      })
    },
    son(data){
      console.log(data)
      this.contents=data
    }
  },
  mounted () {
    this.gethot()
    this.getlits()
  }
}
</script>

<style>
.swinediseaseTop h1{
    font-size: 20px;
    color: #8aafce;
    position: relative;
    text-align: left;
    margin-left:30px;
}
.swinediseaseTop h1::before{
    content: "";
    width: 24px;
    height: 28px;
    position: absolute;
    background: url(../../../assets/img/u166.svg) no-repeat;
    left: -30px;
    top: 0;
}
.swinediseaseTop ul{
    margin-top: 10px;
}
.swinediseaseTop li{
    width: 600px;
    justify-content: space-between;
    font-size: 18px;
    line-height: 40px;
}
.swinediseaseTop li a{
    color: #333;
}
.swinediseaseTop li>p{
    color: red;
}
.swinediseaseCenter>ul{
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    font-size: 16px;
}
.swinediseaseCenter>ul>li{
    padding: 0 5px;
    margin-top: 20px;
    cursor: pointer;
}
.swinediseaseCenter>ul>li+li{
    border-top: 1px dashed #333;
}
.swinediseaseCenter>ul>li div:nth-child(1){
    width: 150px;
    text-align: left;
    margin-top: 10px;
}
.swinediseaseCenter>ul>li div:nth-child(2){
    line-height: 40px;
    flex-wrap: wrap;

}
.swinediseaseCenter>ul>li div:nth-child(2) ul{
    flex-wrap: wrap;
    width: 1000px;

}
.swinediseaseCenter>ul>li div:nth-child(2) li{
    margin-right: 40px;
}
.swinediseaseBottom {
   padding-top: 10px;
   text-align: left;
}
.swinediseaseBottom h1{
    font-size: 20px;
    line-height: 60px;
    text-align: center;
}
.swinediseaseBottom p{
    margin: 10px 0;
}
</style>
